import React, { Component } from 'react'
import Roulette from './Common/Roulette'

export default class Modeling3D extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'modeling-3D',
                title: {
                    text: '3D',
                },
                subtitle: {
                    text: 'Modeling',
                },
                gallery: [
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/real_estate_-_hero-min.png?1679576159',
                        title: 'Real Estate',
                        content: `
                        We can produce your virtual house, or create your virtual apartment from a plan to boost your sales. It showcases properties in a unique, memorable way, reducing the need for in-person viewings and standing out from the competition
                        `,
                        isContact: true,
                        possibilities: [
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--Real-estate-poss-1-min.png?1680103172',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--Real-estate-poss-3-min.png?1680103172',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--Real-estate-poss-4-min.png?1680103172',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--Real-estate-poss-2-min.png?1680103172',
                                path: ''
                            }
                        ]
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/automobile_-_hero-min.png?1679576159',
                        title: 'Automotive',
                        content: `
                        Our team of specialists can virtually create the car of your dreams. This offers you the possibility to use your new virtual car in the metaverse or for any other virtual need.
                        `,
                        isContact: true,
                        possibilities: [
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--automobile-poss-2-min.png?1680179159',
                                path: ''
                            },
                            {
                                src: "https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--automobile-poss-4-min.png?1680179159",
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--automobile-poss-1-min.png?1680179159',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--automobile-poss-3-min.png?1680179159',
                                path: ''
                            }
                        ]
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling-object_-_hero-min.png?1679504867',
                        title: 'General modelisation',
                        content: `
                        We can reproduce any real object virtually, in a hyper-realistic way.
                        `,
                        isContact: true,
                        possibilities: [
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--object-poss-3-min.png?1679504867',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--object-poss-2-min.png?1679504867',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--object-poss-4-min.png?1679504867',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d-modeling--object-poss-1-min.png?1679504867',
                                path: ''
                            }
                        ]
                    },
                ]
            }
        }
    }

    render() {
        return <Roulette
            page={this.state.page}
        />
    }
}
