import React, { Component } from 'react'
import axios from 'axios'
import Titles from '../../Common/Titles'
import OtpInput from 'react-otp-input';

export default class SignIn extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isLoading: true,
        otp: '',
        initialValidated: false,
        isResendDisplayed: true,
      }
    }
    
    componentDidMount = () => {
        this.isIpValid();

        if (JSON.parse(localStorage.getItem('user')) && this.isTokenValid()) {
            window.open('/kmUrx', '_self')
        }
    }

    isIpValid = () => {
        axios.get(`https://versemail.azurewebsites.net/user/`).then(response => {
            if (response) {
                this.setState({
                    isLoading: false
                })
            } else {
                window.open('/', '_self')
            }
        }).catch(error => {
            window.open('/', '_self')
        });
    }

    isTokenValid = () => {
        const token = this.getCurrentToken();
        if (!token || token === undefined)
            return false;
        else if (this.parseJwt(token).exp < Date.now() / 1000) {
            localStorage.clear();
            return false;
        }
        return true;
    }

    getCurrentToken = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken)
            return user.accessToken;
    }

    parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return {exp: 0};
        }
    };

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSignIn = (e) => {
        e.preventDefault()
        
        if (!this.state.initialValidated) {
            axios.post(`https://versemail.azurewebsites.net/user/login/`, {login: this.state.login, password: this.state.password}).then(response => {
                if (response) {
                    this.setState({
                        initialValidated: true
                    })
                } else {
                    alert('error with your credentials')
                }
            }).catch(error => {
                alert('error with your credentials')
            });
        } else {
            axios.post(`https://versemail.azurewebsites.net/user/login/verify`, {login: this.state.login, password: this.state.password, otp: this.state.otp}).then(response => {
                if (response?.data?.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    
                    window.open('/kmUrx', '_self')
                } else {
                    alert('OTP not valid or error with your credentials')
                }
            }).catch(error => {
                alert('OTP not valid or error with your credentials')
            });
        }
    }

    sendOTP = (e) => {
        e.preventDefault();

        axios.post(`https://versemail.azurewebsites.net/user/login/`, { login: this.state.login, password: this.state.password }).then(response => {
            if (response) {
                this.setState({
                    otp: '',
                    isResendDisplayed: false
                })
            } else {
                alert('error with your credentials')
            }
        }).catch(error => {
            alert('error with your credentials')
        });
    }

    switchRender = () => {
        if (this.state.isLoading)
            return (
                <div style={{height: "calc(100vh - 50px)", display: "flex"}}>
                    <h3 style={{margin: "auto", textAlign: 'center'}}>Checking your browser...</h3>
                </div>
            )
        else 
            return this.renderSignIn()
    }

    renderSignIn = () => {
        return (
            <div id='games'>
                <Titles
                    title={'Sign'}
                    subtitle={'In'}
                />

                <section className='join-us card-element'>
                    <form 
                        className="form" 
                        autoComplete='off'
                        onSubmit={this.handleSignIn}
                    >
                        <div className='row'>
                            <div className='input-container col-12'>
                                <label className='label'>Login</label>
                                <input
                                    type='email'
                                    placeholder='Your login'
                                    name='login'
                                    value={this.state.login || ''}
                                    required={true}
                                    onChange={(e) => this.handleInput(e)}
                                    className={this.state?.isInvalid ? 'error-required' : ''}
                                />
                            </div>

                            <div className='input-container col-12'>
                                <label className='label'>Password</label>
                                <input
                                    type={'password'}
                                    placeholder='Your password'
                                    name='password'
                                    value={this.state.password || ''}
                                    required={true}
                                    onChange={(e) => this.handleInput(e)}
                                    className={this.state?.isInvalid ? 'error-required' : ''}
                                />
                            </div>

                            {this.renderOTP(this.state.initialValidated)}

                            <div className='input-container col-12'>
                                <div className='submit-container'>
                                    <input type='submit' className={'submit'} value={'Sign In'} style={{maxWidth: 'unset', width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

            </div>
        )
    }

    setOtp = (value) => {
        this.setState({
            otp: value
        })
    }

    renderOTP = (initialValidated) => {
        if (initialValidated)
            return (
                <div className='otp-container' style={{width: 'fit-content', margin: 'auto'}}>
                    <OtpInput
                        value={this.state.otp}
                        onChange={this.setOtp}
                        numInputs={4}
                        separator={<span>-</span>}
                        shouldAutoFocus={true}
                    />

                    {
                        this.state.isResendDisplayed ? 
                        <a href='#' onClick={(e) => this.sendOTP(e)}>Send another OTP code</a> :
                        <label>New code sent</label>
                    }
                </div>
            
        );
    }

    render() {
        return this.switchRender()
    } 
}