import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import '../../styles/fonts.css'
import ARMRVR from '../../components/ARMRVR'
import Modeling3D from '../../components/Modeling3D'
import Scaning from '../../components/Scaning'
import LivePerformance from '../../components/LivePerformance'
import Motion from '../../components/Edit/interfaces/Motion'
import GamesEdit from '../../components/Edit/interfaces/GamesEdit'
import Join from '../../components/Join'
import Hero from '../../components/3D/Hero'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Common/Header'
import SEO from '../../components/Common/SEO'
import About from '../../components/Edit/interfaces/About'
import SignIn from '../../components/Edit/interfaces/SignIn'

export default class Sign extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isTransparent: false,
            isLoaded: false
        }
        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    render() {
        return (
            <>
                <Header isTransparent={this.state.isTransparent} isLoaded={this.state.isLoaded}/>
                <SignIn/>
            </>
        )
    }
}
